.maincontainer {
  padding: 5vw;
  overflow-y: scroll;
  height: 100vh;
  background: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3vh;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10vh;
}

.logoimage {
  width: 25vw;
  height: auto;
}

.progressholder {
  width: 100%;
  padding: 5vh 0vw;
}

.heading {
  font-weight: 300;
  font-size: 2vw;
}

.screenheading {
  font-size: 2vw;
  font-weight: 300;
  color: #c7dbff;
  font-family: "Satoshi-Medium";
}

.hardgrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3vw;
  padding: 2vh 0vh;
}

.card {
  background: whitesmoke;
  width: 100%/3;
  padding: 1vw;
  border-radius: 10px;
}

.tophardbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2vh 0vw;
}

.harddetails {
  font-size: 1.4vw;
  font-weight: 400;
  font-family: "Satoshi-Medium";
}

.hardvalue {
  font-size: 0.8vw;
  font-weight: 300;
  font-family: "Satoshi-Medium";
}

.hardselectholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  padding: 5vh 0vw;
}

.hardcard {
  padding: 1vw;
  border-radius: 10px;
  background: #e4e4e4;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 20vw;
}

.leftdetails {
  flex: 1.5;
}

.smallhead {
  font-size: 1vw;
  font-family: "Satoshi-Medium";
}

.hardcardblue {
  padding: 1vw;
  border-radius: 10px;
  background: #c7dbff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 20vw;
}

.nextbutton {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: #0043bf;
  border: none;
  color: white;
  font-family: "Satoshi-Medium";
}

.nextbuttondisabled {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: grey;
  border: none;
  color: #2f2f2f;
  font-family: "Satoshi-Medium";
}

.nextbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.nextbuttondisabled:hover {
  cursor: not-allowed;
  transform: scale(1.04);
}
