@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Satoshi-Light";
  src: url("./fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Satoshi-Medium";
  src: url("./fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: normal;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Satoshi";
}
