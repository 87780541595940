.maincontainer {
  padding: 5vw;
  overflow-y: scroll;
  height: 100vh;
  background: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3vh;
}

.quoteholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  flex-direction: column;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10vh;
}

.logoimage {
  width: 25vw;
  height: auto;
}

.progressholder {
  width: 100%;
  padding: 5vh 0vw;
}

.heading {
  font-weight: 300;
  font-size: 2vw;
}

.screenheading {
  font-size: 2vw;
  font-weight: 300;
  color: #c7dbff;
  font-family: "Satoshi-Medium";
}

.leftholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  padding: 2vw;
  background: #c7dbff;
  border-radius: 10px;
  width: 45vw;
  min-height: 25vh;
}

.headingleft {
  color: #001438;
  font-family: "Satoshi-Light";
  font-size: 1.2vw;
}

.headingright {
  color: white;
  font-family: "Satoshi-Light";
  font-size: 1.2vw;
}

.priceleft {
  font-size: 3vw;
  color: #001438;
  font-family: "Satoshi-Medium";
}

.priceright {
  font-size: 3vw;
  color: white;
  font-family: "Satoshi-Medium";
}

.smalltextleft {
  font-size: 0.8vw;
  font-family: "Satoshi-Light";
  color: #001438;
}

.smalltextright {
  font-size: 0.8vw;
  font-family: "Satoshi-Light";
  color: white;
}

.rightholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  padding: 2vw;
  background: #0043bf;
  border-radius: 10px;
  width: 45vw;
  min-height: 25vh;
}

.innerquoteholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3vw;
}
