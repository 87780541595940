.maincontainer {
  padding: 5vw;
  overflow-y: scroll;
  height: 100vh;
  background: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3vh;
}

.progressholder {
  width: 100%;
  padding: 5vh 0vw;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10vh;
}

.logoimage {
  width: 25vw;
  height: auto;
}

.heading {
  font-weight: 300;
  font-size: 2vw;
}

.screenheading {
  font-size: 2vw;
  font-weight: 300;
  color: #c7dbff;
  font-family: "Satoshi-Medium";
}

.accordionholder {
  padding: 2vh 0vh;
  width: 60vw;
}

.accordion {
  margin: 1vh 0vh;
}

.featurecategory {
  font-size: 1.2vw;
  font-weight: 300;
  font-family: "Satoshi-Medium";
  flex: 10;
  width: 12vw;
}

.featureselectholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.featurename {
  font-weight: 300;
  font-size: 0.8vw;
  font-family: "Satoshi-Medium";
}

.featurenameintra {
  font-weight: 300;
  font-size: 0.8vw;
  font-family: "Satoshi-Medium";
}

.nextbutton {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: #0043bf;
  border: none;
  color: white;
  font-family: "Satoshi-Medium";
}

.nextbuttondisabled {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: grey;
  border: none;
  color: #2f2f2f;
  font-family: "Satoshi-Medium";
}

.totalfeatures {
  font-size: 1.5vw;
  font-weight: 300;
  color: #c7dbff;
  font-family: "Satoshi-Medium";
}

.nextbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.nextbuttondisabled:hover {
  cursor: not-allowed;
  transform: scale(1.04);
}

input[type="checkbox"] {
  accent-color: #0043bf;
}

.featurecheck {
  width: 2.5vh;
  height: 2.5vh;
}

.accordionhead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectholder {
  width: 10vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
  flex: 4;
}

.userdetailholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
}

.selectcameracount {
  width: 10vw;
  font-size: 0.8vw;
  border-radius: 5px;
  font-family: "Satoshi-Medium";
}
