.maincontainer {
  padding: 5vw;
  overflow-y: scroll;
  height: 100vh;
  background: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3vh;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10vh;
}

.logoimage {
  width: 25vw;
  height: auto;
}

.heading {
  font-weight: 300;
  font-size: 2vw;
}

.progressholder {
  width: 100%;
  padding: 5vh 0vw;
}

.screenheading {
  font-size: 2vw;
  font-weight: 300;
  color: #c7dbff;
  font-family: "Satoshi-Medium";
}

.rangeslider {
  width: 100%;
  padding: 2vh 0vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
}

.ranger {
  flex: 1.5;
}

.rangenumber {
  margin-top: -6vh;
  color: white;
  font-family: "Satoshi-Medium";
}

.inputcount {
  font-size: 1vw;
  padding: 1vh;
  margin: 2vh 0vw;
  border-radius: 5px;
  border: 1px solid grey;
}

.nextbutton {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: #0043bf;
  border: none;
  color: white;
  font-family: "Satoshi-Medium";
}

.nextbuttondisabled {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: grey;
  border: none;
  color: #2f2f2f;
  font-family: "Satoshi-Medium";
}

.nextbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.nextbuttondisabled:hover {
  cursor: not-allowed;
  transform: scale(1.04);
}
