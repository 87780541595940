.maincontainer {
  padding: 5vw;
  overflow-y: scroll;
  height: 100vh;
  background: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3vh;
}

.logoimage {
  width: 25vw;
  height: auto;
}

.progressholder {
  width: 100%;
  padding: 5vh 0vw;
}

.heading {
  font-weight: 300;
  font-size: 2vw;
}

.screenheading {
  font-size: 2vw;
  font-weight: 300;
  color: #c7dbff;
  font-family: "Satoshi-Medium";
}

.selectholder {
  padding: 3vh 0vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5vw;
}

.selectfield {
  padding: 2vw;
  background: #c7dbff;
  height: 30vh;
  border-radius: 10px;
}

.industrytop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3vh;
}

.checklabel {
  font-size: 1vw;
  font-family: "Satoshi-Medium";
  font-weight: 300;
}

.industryimage {
  width: 14vw;
  height: auto;
}

.nextbutton {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: #0043bf;
  border: none;
  color: white;
  font-family: "Satoshi-Medium";
}

.nextbuttondisabled {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: grey;
  border: none;
  color: #2f2f2f;
  font-family: "Satoshi-Medium";
}

.nextbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.nextbuttondisabled:hover {
  cursor: not-allowed;
  transform: scale(1.04);
}
