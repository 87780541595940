.maincontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20vh;
  padding: 2vw;
  height: 100vh;
  background: #2f2f2f;
}

.leftlogin {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5vh;
  flex-direction: column;
  flex: 1;
}

.heading {
  font-weight: 600;
  font-size: 2vw;
  color: white;
}

.inputlabel {
  font-size: 0.8vw;
  color: white;
  font-weight: 300;
}

.input {
  font-size: 1vw;
  width: 20vw;
  padding: 1vh;
  border-radius: 5px;
  border: 1px solid #c7dbff;
  color: #c7dbff;
  background: transparent;
}

.logo {
  width: 20vw;
  height: auto;
}

.errormessage {
  color: #ff0000;
  font-size: 12px;
}

.nextbutton {
  font-size: 1.3vw;
  font-weight: 300;
  padding: 0.5vh 4vw;
  border-radius: 5px;
  background: #0043bf;
  border: none;
  color: white;
}

.nextbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.preloginimage {
  width: 55vw;
  height: auto;
}

.rightimage {
  flex: 1.2;
}
